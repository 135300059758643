import React from "react"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import { LinkRenderer, HeadingRenderer } from "../utilities/markdownUtils"
import Layout from "../components/common/layout"
import markdownStyles from "../styles/common/markdown.module.scss"

// https://github.com/remarkjs/react-markdown/issues/69

const LegalTemplate = ({ data, location }) => (
  <Layout
    pageMeta={{
      title: `${data.strapiDocumentiLegali.titolo}`,
      keywords: [`${data.strapiDocumentiLegali.titolo}`],
      description: `${data.strapiDocumentiLegali.titolo}`,
      link: `/${data.strapiDocumentiLegali.slug}`,
    }}
    location={location}
  >
    <h1 className="page-title">{data.strapiDocumentiLegali.titolo}</h1>
    <div className={markdownStyles.legal}>
      <Markdown
        className={markdownStyles.testo}
        renderers={{ heading: HeadingRenderer, link: LinkRenderer }}
        source={data.strapiDocumentiLegali.testo}
      />
    </div>
  </Layout>
)

export default LegalTemplate

export const query = graphql`
  query LegalQuery($slug: String!) {
    strapiDocumentiLegali(slug: { eq: $slug }) {
      strapiId
      testo
      slug
      titolo
    }
  }
`
